var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vipAgree" },
    [
      _vm.instructions
        ? _c(
            "div",
            {
              staticClass: "instructions",
              staticStyle: { "line-height": "2" }
            },
            [
              _c("div", {
                staticStyle: {
                  "white-space": "pre-wrap",
                  "word-break": "keep-all"
                },
                domProps: { innerHTML: _vm._s(_vm.instructions) }
              })
            ]
          )
        : _vm._l(_vm.contentArr, function(item, index) {
            return _c("div", { key: index, staticClass: "contentBox" }, [
              _c("div", { staticClass: "contentTitle" }, [
                _vm._v("\n      " + _vm._s(item.title) + "\n    ")
              ]),
              _c("div", { staticClass: "contentInner" }, [
                _vm._v("\n      " + _vm._s(item.content) + "\n    ")
              ])
            ])
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }