<template>
  <div class="vipAgree">
    <div v-if="instructions" class="instructions" style="line-height:2">
      <div
        v-html="instructions"
        style="white-space:pre-wrap;word-break: keep-all"
      ></div>
    </div>
    <div
      v-else
      class="contentBox"
      v-for="(item, index) in contentArr"
      :key="index"
    >
      <div class="contentTitle">
        {{ item.title }}
      </div>
      <div class="contentInner">
        {{ item.content }}
      </div>
    </div>
  </div>
</template>
<script>
import { vipInstructions } from "@/api/newVersion/vip";
export default {
  data() {
    return {
      instructions: null,
    };
  },
  mounted() {
    this.getVipInstructions();
  },
  methods: {
    async getVipInstructions() {
      let result = await vipInstructions();
      if (result.code == 200) {
        this.instructions = result.data.data;
      }
    },
  },
  computed: {
    contentArr() {
      let contentArr = [];
      let language = localStorage.getItem("langCode") || 1;
      if (parseInt(language) === 1) {
        contentArr = [
          {
            content:
              "1、账号注销后，未使用完的权益同时失效，平台不作任何处理；",
          },
          {
            content: "2、为保障您的权益，游客模式下无法购买。",
          },
        ];
      } else {
        contentArr = [
          {
            content:
              "1、After account cancellation, the unused rights and benefits will be invalid at the same time, and the platform will not make any processing.",
          },
          {
            content:
              "2. In order to protect your rights and interests, purchases cannot be made in visitor mode.",
          },
        ];
      }
      return contentArr;
    },
  },
};
</script>

<style lang="stylus" scoped>
.vipAgree {
    flex: 1;
    // width: 80%;
    box-sizing: border-box;
    padding: 0 2px;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0px;
    }

    .contentBox {
        width: 100%;
        font-size: 14px;

        .contentTitle {
            margin-top: 5px;
            line-height: 26px;
            color: #333333;
            font-weight: bold;
        }

        .contentInner {
            // margin-top: 5px;
            line-height: 24px;
            color: #777777;
            word-break: keep-all;
        }
    }
}
</style>
